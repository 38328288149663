<template>
  <div>
    <!-- pic5C783A86A689D8696823156B444E5376.jpg -->
    <img class="rounded" style="width: 440px; height: 294px;" src="@/assets/news-network/kids-development-panel-inspires-1.jpg">
    <p>
      Imagine if you got the earliest insider looks at LEGO Universe. Now, how would you feel if you also got to lend the game’s developers extra inspiration? Got that picture?
    </p>
    <p>
      Great! That’s what it’s like for the lucky few who make up the LEGO Universe Kids’ Development Panel!
    </p>
    <p>
      Fourteen panel members gathered at the recent
      <router-link :to="{ name: 'story', params: {id: 80859} }">LEGO Universe Partners’ (LUPs) event</router-link>
      to boost the inspiration levels on the latest game building! At the LUPs event, the kids broke into three teams before being presented with the first of two building puzzles.
    </p>
    <p>
      The fun challenges were specially crafted to reflect obstacles faced by the LEGO Universe development team: <i>How would your minifigure cross a LEGO Universe canyon while protecting a treasure? What would you build to entertain a powerful but sleepy ninja?</i>
    </p>
    <!-- picB95914EAF1BF19A79F1073109209236A.jpg -->
    <img class="rounded" style="width: 440px; height: 248px;" src="@/assets/news-network/kids-development-panel-inspires-2.jpg">
    <p>
      The kids’ teams overcame these challenges by creating solutions like hovercrafts with camouflaged compartments, sliding pods, a lava-flow obstacle course and an amazing maze game! The Kids Development Panel members were then invited to see the LUPs’ work.
    </p>
    <p>
      Six teams of LUPs had spent days making amazing physical models and new LEGO Universe game levels. Each LUPs team wowed the audience with their creations, and, as guests of honor, the Kids Development panel members got to vote for their favorites.
    </p>
    <div style="clear:both;"></div>
    <!-- picA15664133EB0E39755C4FC036AAABF94.jpg -->
    <img class="rounded" style="width: 440px; height: 306px;" src="@/assets/news-network/kids-development-panel-inspires-3.jpg">

    <p>
      But picking a preferred LEGO Universe game level was a prickly problem since they were all fantastically fun! Within those original virtual realms, LEGO minifigures flew from volcanoes, explored, dove into underwater worlds, solved mysteries and collected treasure!
    </p>
    <p>
      With inspiring feedback from the Kids Development panel, the grand finale of the 2008 LUPs event became more than just an ending. Instead, it was transformed into the beginning of a new era in the evolution of LEGO Universe!
    </p>
    <p>
      Lock your web browser right here on the LEGO Universe News Network for more coverage of the Kids’ Development Panel’s contributions to the game!
    </p>
    <p>
      <i>Follow this link if you missed our video coverage of the 2008 LUPs event:</i>
      <br><router-link :to="{ name: 'story', params: {id: 80861}}">
        2008 LEGO Universe Partners event video
      </router-link>
    </p>
  </div>
</template>
